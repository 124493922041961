/*
 *  Radius
*/
/*
 * Config
 * @ Variables
 *
*/
/*
 *  Use Defaults
*/
/*
 *  FWAD base Font size
*/
/*
 * H1
*/
/*
 * header fonts mobile devices
 */
/*
 * header fonts tablets(portrait)
 */
/*
 * header fonts tablets(landscape) and small desktop
 */
/*
 * header fonts large desktop
 */
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*/*
 * body-2
*/
/*
 * body-3
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ar-1
*/
/*/*
 * body-ar-2
*/
/*
 * body-ar-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ru-1
*/
/*/*
 * body-ru-2
*/
/*
 * body-ru-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-zh-1
*/
/*/*
 * body-zh-2
*/
/*
 * body-zh-3
*/
/*
* z-index variables
*/
/*
* form placeholder color variables
*/
/*
 * Background Image
 */
/*
  * Line height font
  */
/*
  * Border Radius
  */
/*
  * Box Sizing
  */
/*
  * Box Shadow
  */
/*
  * Border Radius Separate
  */
/*
  * Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * large screen Desktop (PC's)
  */
/*
  * Wide screen Devices
  */
/*
  * upto Tablet
  */
/*
  * Tablet
  */
/*
  * TabletOnly
  */
/*
  * Mobile
  */
/*
  * Transition
     usage example
       a{
       color: gray;
       @include transition(color .3s ease);
       &:hover {
         color: black;
       }
 }
  */
/*
  * Opacity
  */
/*
  * Clearfix
  */
/*
  * Configure vendor
  */
/*
  * Transform
  */
/**
 *   Use Arrow inside the :after and :before pseudo elements
 *   and change the position as per needed
 *   @include arrows('right', 7px, 7px, #000);
 **/
/**
 *   Background gradient mixin
 **/
/*
  * Flex
  */
/*
 * @ Fonts
 *
*/
/*
 * Base
 * @ Colors
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
.tenant-fwad .c-text-with-cta,
.tenant-fwad .success-notification .c-text-with-cta {
  margin-top: 34px;
  padding-top: 45px;
  padding-bottom: 45px;
  /* Medium devices (desktops, 1024px and up) */
  background: #e50000;
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-text-with-cta,
  .tenant-fwad .success-notification .c-text-with-cta {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.tenant-fwad .c-text-with-cta .heading-2,
.tenant-fwad .success-notification .c-text-with-cta .heading-2 {
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 33px;
  line-height: 39px;
  letter-spacing: 0.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-fwad .c-text-with-cta .heading-2,
  .tenant-fwad .success-notification .c-text-with-cta .heading-2 {
    font-size: 33px;
    line-height: 39px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-text-with-cta .heading-2,
  .tenant-fwad .success-notification .c-text-with-cta .heading-2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-fwad .c-text-with-cta .heading-2,
  .tenant-fwad .success-notification .c-text-with-cta .heading-2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
body.lang-ar .tenant-fwad .c-text-with-cta .heading-2,
body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .heading-2 {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 33px;
  line-height: 39px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .tenant-fwad .c-text-with-cta .heading-2,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .heading-2 {
    font-size: 33px;
    line-height: 39px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .tenant-fwad .c-text-with-cta .heading-2,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .heading-2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .tenant-fwad .c-text-with-cta .heading-2,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .heading-2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
body.lang-ru .tenant-fwad .c-text-with-cta .heading-2,
body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .heading-2 {
  font-family: "Noto Sans Black", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .tenant-fwad .c-text-with-cta .heading-2,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .heading-2 {
    font-size: 30px;
    line-height: 39px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .tenant-fwad .c-text-with-cta .heading-2,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .heading-2 {
    font-size: 42px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .tenant-fwad .c-text-with-cta .heading-2,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .heading-2 {
    font-size: 42px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
body.lang-zh .tenant-fwad .c-text-with-cta .heading-2,
body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .heading-2 {
  font-family: "Norwester", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 33px;
  line-height: 39px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .tenant-fwad .c-text-with-cta .heading-2,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .heading-2 {
    font-size: 33px;
    line-height: 39px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .tenant-fwad .c-text-with-cta .heading-2,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .heading-2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .tenant-fwad .c-text-with-cta .heading-2,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .heading-2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
.tenant-fwad .c-text-with-cta.link-pass-success .w--content,
.tenant-fwad .success-notification .c-text-with-cta.link-pass-success .w--content {
  padding: 0;
}
.tenant-fwad .c-text-with-cta .w--content,
.tenant-fwad .success-notification .c-text-with-cta .w--content {
  padding: 0 0 55px 0;
}
.tenant-fwad .c-text-with-cta .w--content .body-copy-5,
.tenant-fwad .success-notification .c-text-with-cta .w--content .body-copy-5 {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-fwad .c-text-with-cta .w--content .body-copy-5,
  .tenant-fwad .success-notification .c-text-with-cta .w--content .body-copy-5 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-text-with-cta .w--content .body-copy-5,
  .tenant-fwad .success-notification .c-text-with-cta .w--content .body-copy-5 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-fwad .c-text-with-cta .w--content .body-copy-5,
  .tenant-fwad .success-notification .c-text-with-cta .w--content .body-copy-5 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-ar .tenant-fwad .c-text-with-cta .w--content .body-copy-5,
body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .w--content .body-copy-5 {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .tenant-fwad .c-text-with-cta .w--content .body-copy-5,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .w--content .body-copy-5 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .tenant-fwad .c-text-with-cta .w--content .body-copy-5,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .w--content .body-copy-5 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .tenant-fwad .c-text-with-cta .w--content .body-copy-5,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .w--content .body-copy-5 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-ru .tenant-fwad .c-text-with-cta .w--content .body-copy-5,
body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .w--content .body-copy-5 {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .tenant-fwad .c-text-with-cta .w--content .body-copy-5,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .w--content .body-copy-5 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .tenant-fwad .c-text-with-cta .w--content .body-copy-5,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .w--content .body-copy-5 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .tenant-fwad .c-text-with-cta .w--content .body-copy-5,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .w--content .body-copy-5 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-zh .tenant-fwad .c-text-with-cta .w--content .body-copy-5,
body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .w--content .body-copy-5 {
  font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .tenant-fwad .c-text-with-cta .w--content .body-copy-5,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .w--content .body-copy-5 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .tenant-fwad .c-text-with-cta .w--content .body-copy-5,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .w--content .body-copy-5 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .tenant-fwad .c-text-with-cta .w--content .body-copy-5,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .w--content .body-copy-5 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
.tenant-fwad .c-text-with-cta .w--content .title,
.tenant-fwad .success-notification .c-text-with-cta .w--content .title {
  margin: 0;
  color: #ffffff;
}
.tenant-fwad .c-text-with-cta .w--content .description,
.tenant-fwad .success-notification .c-text-with-cta .w--content .description {
  color: #ffffff;
  margin-top: 12px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-fwad .c-text-with-cta .w--content .description,
  .tenant-fwad .success-notification .c-text-with-cta .w--content .description {
    margin-top: 8px;
  }
}
.tenant-fwad .c-text-with-cta .w--bottom,
.tenant-fwad .success-notification .c-text-with-cta .w--bottom {
  display: inline-block;
  /* Small devices (tablets, 768px and up) */
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .tenant-fwad .c-text-with-cta .w--bottom,
  .tenant-fwad .success-notification .c-text-with-cta .w--bottom {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}
.tenant-fwad .c-text-with-cta .w--bottom .btn.btn-primary,
.tenant-fwad .success-notification .c-text-with-cta .w--bottom .btn.btn-primary {
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-fwad .c-text-with-cta .w--bottom .btn.btn-primary,
  .tenant-fwad .success-notification .c-text-with-cta .w--bottom .btn.btn-primary {
    margin-top: 20px;
  }
  .tenant-fwad .c-text-with-cta .w--bottom .btn.btn-primary:first-child,
  .tenant-fwad .success-notification .c-text-with-cta .w--bottom .btn.btn-primary:first-child {
    margin-top: 0;
  }
}
.tenant-fwad .c-text-with-cta .w--bottom .btn-secondary,
.tenant-fwad .success-notification .c-text-with-cta .w--bottom .btn-secondary {
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-fwad .c-text-with-cta .w--bottom .btn-secondary,
  .tenant-fwad .success-notification .c-text-with-cta .w--bottom .btn-secondary {
    margin: 10px 5px;
  }
}
.tenant-fwad .c-text-with-cta .btn-secondary:before,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary:before {
  left: 0;
}
.tenant-fwad .c-text-with-cta .btn-secondary:hover:before,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary:hover:before {
  left: 0;
}
.tenant-fwad .c-text-with-cta .btn.btn-primary,
.tenant-fwad .c-text-with-cta .btn-secondary,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary {
  /* < 768px */
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  padding: 0;
  display: inline-block;
  text-align: center;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  transform: skew(-17deg);
  text-decoration: none;
  cursor: pointer;
  position: relative;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  background-color: #e50000;
  border: 1px solid transparent;
  color: #ffffff;
  /* Medium devices (desktops, 1024px and up) */
  background-color: #ffffff;
  border: 1px solid transparent;
  color: #000000;
  /* Medium devices (desktops, 1024px and up) */
  border: none;
  color: #e50000;
  margin: 0 5px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-fwad .c-text-with-cta .btn.btn-primary,
  .tenant-fwad .c-text-with-cta .btn-secondary,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary {
    display: block;
  }
}
@media (min-width: 768px) {
  .tenant-fwad .c-text-with-cta .btn.btn-primary,
  .tenant-fwad .c-text-with-cta .btn-secondary,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-text-with-cta .btn.btn-primary,
  .tenant-fwad .c-text-with-cta .btn-secondary,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-fwad .c-text-with-cta .btn.btn-primary,
  .tenant-fwad .c-text-with-cta .btn-secondary,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-ar .tenant-fwad .c-text-with-cta .btn.btn-primary,
body.lang-ar .tenant-fwad .c-text-with-cta .btn-secondary,
body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary,
body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn-secondary {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .tenant-fwad .c-text-with-cta .btn.btn-primary,
  body.lang-ar .tenant-fwad .c-text-with-cta .btn-secondary,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn-secondary {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .tenant-fwad .c-text-with-cta .btn.btn-primary,
  body.lang-ar .tenant-fwad .c-text-with-cta .btn-secondary,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn-secondary {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .tenant-fwad .c-text-with-cta .btn.btn-primary,
  body.lang-ar .tenant-fwad .c-text-with-cta .btn-secondary,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn-secondary {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-ru .tenant-fwad .c-text-with-cta .btn.btn-primary,
body.lang-ru .tenant-fwad .c-text-with-cta .btn-secondary,
body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary,
body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn-secondary {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .tenant-fwad .c-text-with-cta .btn.btn-primary,
  body.lang-ru .tenant-fwad .c-text-with-cta .btn-secondary,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn-secondary {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .tenant-fwad .c-text-with-cta .btn.btn-primary,
  body.lang-ru .tenant-fwad .c-text-with-cta .btn-secondary,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn-secondary {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .tenant-fwad .c-text-with-cta .btn.btn-primary,
  body.lang-ru .tenant-fwad .c-text-with-cta .btn-secondary,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn-secondary {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-zh .tenant-fwad .c-text-with-cta .btn.btn-primary,
body.lang-zh .tenant-fwad .c-text-with-cta .btn-secondary,
body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary,
body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn-secondary {
  font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .tenant-fwad .c-text-with-cta .btn.btn-primary,
  body.lang-zh .tenant-fwad .c-text-with-cta .btn-secondary,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn-secondary {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .tenant-fwad .c-text-with-cta .btn.btn-primary,
  body.lang-zh .tenant-fwad .c-text-with-cta .btn-secondary,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn-secondary {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .tenant-fwad .c-text-with-cta .btn.btn-primary,
  body.lang-zh .tenant-fwad .c-text-with-cta .btn-secondary,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn-secondary {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
.tenant-fwad .c-text-with-cta .btn.btn-primary a,
.tenant-fwad .c-text-with-cta .btn.btn-primary button,
.tenant-fwad .c-text-with-cta .btn-secondary a,
.tenant-fwad .c-text-with-cta .btn-secondary button,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary a,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary button {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  text-decoration: none;
  background-color: transparent;
  display: inline-block;
  transform: skew(17deg);
  color: inherit;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-fwad .c-text-with-cta .btn.btn-primary a,
  .tenant-fwad .c-text-with-cta .btn.btn-primary button,
  .tenant-fwad .c-text-with-cta .btn-secondary a,
  .tenant-fwad .c-text-with-cta .btn-secondary button,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary a,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-text-with-cta .btn.btn-primary a,
  .tenant-fwad .c-text-with-cta .btn.btn-primary button,
  .tenant-fwad .c-text-with-cta .btn-secondary a,
  .tenant-fwad .c-text-with-cta .btn-secondary button,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary a,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-fwad .c-text-with-cta .btn.btn-primary a,
  .tenant-fwad .c-text-with-cta .btn.btn-primary button,
  .tenant-fwad .c-text-with-cta .btn-secondary a,
  .tenant-fwad .c-text-with-cta .btn-secondary button,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary a,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-ar .tenant-fwad .c-text-with-cta .btn.btn-primary a,
body.lang-ar .tenant-fwad .c-text-with-cta .btn.btn-primary button,
body.lang-ar .tenant-fwad .c-text-with-cta .btn-secondary a,
body.lang-ar .tenant-fwad .c-text-with-cta .btn-secondary button,
body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a,
body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button,
body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn-secondary a,
body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn-secondary button {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .tenant-fwad .c-text-with-cta .btn.btn-primary a,
  body.lang-ar .tenant-fwad .c-text-with-cta .btn.btn-primary button,
  body.lang-ar .tenant-fwad .c-text-with-cta .btn-secondary a,
  body.lang-ar .tenant-fwad .c-text-with-cta .btn-secondary button,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn-secondary a,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn-secondary button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .tenant-fwad .c-text-with-cta .btn.btn-primary a,
  body.lang-ar .tenant-fwad .c-text-with-cta .btn.btn-primary button,
  body.lang-ar .tenant-fwad .c-text-with-cta .btn-secondary a,
  body.lang-ar .tenant-fwad .c-text-with-cta .btn-secondary button,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn-secondary a,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn-secondary button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .tenant-fwad .c-text-with-cta .btn.btn-primary a,
  body.lang-ar .tenant-fwad .c-text-with-cta .btn.btn-primary button,
  body.lang-ar .tenant-fwad .c-text-with-cta .btn-secondary a,
  body.lang-ar .tenant-fwad .c-text-with-cta .btn-secondary button,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn-secondary a,
  body.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn-secondary button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-ru .tenant-fwad .c-text-with-cta .btn.btn-primary a,
body.lang-ru .tenant-fwad .c-text-with-cta .btn.btn-primary button,
body.lang-ru .tenant-fwad .c-text-with-cta .btn-secondary a,
body.lang-ru .tenant-fwad .c-text-with-cta .btn-secondary button,
body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a,
body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button,
body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn-secondary a,
body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn-secondary button {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .tenant-fwad .c-text-with-cta .btn.btn-primary a,
  body.lang-ru .tenant-fwad .c-text-with-cta .btn.btn-primary button,
  body.lang-ru .tenant-fwad .c-text-with-cta .btn-secondary a,
  body.lang-ru .tenant-fwad .c-text-with-cta .btn-secondary button,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn-secondary a,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn-secondary button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .tenant-fwad .c-text-with-cta .btn.btn-primary a,
  body.lang-ru .tenant-fwad .c-text-with-cta .btn.btn-primary button,
  body.lang-ru .tenant-fwad .c-text-with-cta .btn-secondary a,
  body.lang-ru .tenant-fwad .c-text-with-cta .btn-secondary button,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn-secondary a,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn-secondary button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .tenant-fwad .c-text-with-cta .btn.btn-primary a,
  body.lang-ru .tenant-fwad .c-text-with-cta .btn.btn-primary button,
  body.lang-ru .tenant-fwad .c-text-with-cta .btn-secondary a,
  body.lang-ru .tenant-fwad .c-text-with-cta .btn-secondary button,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn-secondary a,
  body.lang-ru .tenant-fwad .success-notification .c-text-with-cta .btn-secondary button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-zh .tenant-fwad .c-text-with-cta .btn.btn-primary a,
body.lang-zh .tenant-fwad .c-text-with-cta .btn.btn-primary button,
body.lang-zh .tenant-fwad .c-text-with-cta .btn-secondary a,
body.lang-zh .tenant-fwad .c-text-with-cta .btn-secondary button,
body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a,
body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button,
body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn-secondary a,
body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn-secondary button {
  font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .tenant-fwad .c-text-with-cta .btn.btn-primary a,
  body.lang-zh .tenant-fwad .c-text-with-cta .btn.btn-primary button,
  body.lang-zh .tenant-fwad .c-text-with-cta .btn-secondary a,
  body.lang-zh .tenant-fwad .c-text-with-cta .btn-secondary button,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn-secondary a,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn-secondary button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .tenant-fwad .c-text-with-cta .btn.btn-primary a,
  body.lang-zh .tenant-fwad .c-text-with-cta .btn.btn-primary button,
  body.lang-zh .tenant-fwad .c-text-with-cta .btn-secondary a,
  body.lang-zh .tenant-fwad .c-text-with-cta .btn-secondary button,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn-secondary a,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn-secondary button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .tenant-fwad .c-text-with-cta .btn.btn-primary a,
  body.lang-zh .tenant-fwad .c-text-with-cta .btn.btn-primary button,
  body.lang-zh .tenant-fwad .c-text-with-cta .btn-secondary a,
  body.lang-zh .tenant-fwad .c-text-with-cta .btn-secondary button,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn-secondary a,
  body.lang-zh .tenant-fwad .success-notification .c-text-with-cta .btn-secondary button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-text-with-cta .btn.btn-primary a:hover, .tenant-fwad .c-text-with-cta .btn.btn-primary a:focus,
  .tenant-fwad .c-text-with-cta .btn.btn-primary button:hover,
  .tenant-fwad .c-text-with-cta .btn.btn-primary button:focus,
  .tenant-fwad .c-text-with-cta .btn-secondary a:hover,
  .tenant-fwad .c-text-with-cta .btn-secondary a:focus,
  .tenant-fwad .c-text-with-cta .btn-secondary button:hover,
  .tenant-fwad .c-text-with-cta .btn-secondary button:focus,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a:hover,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a:focus,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button:hover,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button:focus,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary a:hover,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary a:focus,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary button:hover,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary button:focus {
    border-color: transparent;
    background-color: transparent;
    color: inherit;
    z-index: 3;
    opacity: 1;
    box-shadow: none;
    border: none;
  }
}
.tenant-fwad .c-text-with-cta .btn.btn-primary:before, .tenant-fwad .c-text-with-cta .btn.btn-primary:after,
.tenant-fwad .c-text-with-cta .btn-secondary:before,
.tenant-fwad .c-text-with-cta .btn-secondary:after,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:before,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:after,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary:before,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary:after {
  content: "";
  border-style: solid;
  border-color: #e50000;
  position: absolute;
  z-index: 0;
  box-sizing: content-box;
  transition: all 0.4s ease-in-out;
}
.tenant-fwad .c-text-with-cta .btn.btn-primary:before,
.tenant-fwad .c-text-with-cta .btn-secondary:before,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:before,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary:before {
  width: 0;
  height: 100%;
  border-width: 1px 0 0 0;
  top: -1px;
  right: 0;
  transition-delay: 0s;
}
.tenant-fwad .c-text-with-cta .btn.btn-primary:after,
.tenant-fwad .c-text-with-cta .btn-secondary:after,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:after,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary:after {
  width: 100%;
  height: 0;
  border-width: 0 1px 0 1px;
  top: -1px;
  left: -1px;
  transition-delay: 0s;
  z-index: -1;
}
.lang-ar .tenant-fwad .c-text-with-cta .btn.btn-primary:before,
.lang-ar .tenant-fwad .c-text-with-cta .btn-secondary:before,
.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:before,
.lang-ar .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:before {
  right: auto;
  left: 0;
}
.tenant-fwad .c-text-with-cta .btn.btn-primary a,
.tenant-fwad .c-text-with-cta .btn.btn-primary button,
.tenant-fwad .c-text-with-cta .btn-secondary a,
.tenant-fwad .c-text-with-cta .btn-secondary button,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary a,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary button {
  color: inherit;
  transform: skew(17deg);
  padding: 10px 30px;
  z-index: 2;
}
.tenant-fwad .c-text-with-cta .btn.btn-primary a:before, .tenant-fwad .c-text-with-cta .btn.btn-primary a:after,
.tenant-fwad .c-text-with-cta .btn.btn-primary button:before,
.tenant-fwad .c-text-with-cta .btn.btn-primary button:after,
.tenant-fwad .c-text-with-cta .btn-secondary a:before,
.tenant-fwad .c-text-with-cta .btn-secondary a:after,
.tenant-fwad .c-text-with-cta .btn-secondary button:before,
.tenant-fwad .c-text-with-cta .btn-secondary button:after,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a:before,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a:after,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button:before,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button:after,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary a:before,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary a:after,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary button:before,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary button:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-color: #e50000;
  transform: skew(-17deg);
  transition: all 0.4s ease-in-out;
  z-index: 0;
}
.tenant-fwad .c-text-with-cta .btn.btn-primary a:before,
.tenant-fwad .c-text-with-cta .btn.btn-primary button:before,
.tenant-fwad .c-text-with-cta .btn-secondary a:before,
.tenant-fwad .c-text-with-cta .btn-secondary button:before,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a:before,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button:before,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary a:before,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary button:before {
  width: 0;
  height: 100%;
  border-width: 0 0 1px 0;
  bottom: -1px;
  left: 0;
  animation-direction: alternate;
}
.tenant-fwad .c-text-with-cta .btn.btn-primary a:after,
.tenant-fwad .c-text-with-cta .btn.btn-primary button:after,
.tenant-fwad .c-text-with-cta .btn-secondary a:after,
.tenant-fwad .c-text-with-cta .btn-secondary button:after,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a:after,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button:after,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary a:after,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary button:after {
  width: 100%;
  height: 0;
  border-width: 0 1px 0 1px;
  bottom: -1px;
  right: 4px;
  transition-delay: 0s;
}
.tenant-fwad .c-text-with-cta .btn.btn-primary a:hover,
.tenant-fwad .c-text-with-cta .btn.btn-primary button:hover,
.tenant-fwad .c-text-with-cta .btn-secondary a:hover,
.tenant-fwad .c-text-with-cta .btn-secondary button:hover,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a:hover,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button:hover,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary a:hover,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary button:hover {
  border: none;
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-text-with-cta .btn.btn-primary:hover, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-within, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-visible,
  .tenant-fwad .c-text-with-cta .btn-secondary:hover,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-within,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-visible,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:hover,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-within,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-visible,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:hover,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-within,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-visible {
    background-color: #ffffff;
    color: #000000;
  }
  .tenant-fwad .c-text-with-cta .btn.btn-primary:hover:before, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-within:before, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-visible:before,
  .tenant-fwad .c-text-with-cta .btn-secondary:hover:before,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-within:before,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-visible:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:hover:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-within:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-visible:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:hover:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-within:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-visible:before {
    width: 100%;
  }
  .tenant-fwad .c-text-with-cta .btn.btn-primary:hover:after, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-within:after, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-visible:after,
  .tenant-fwad .c-text-with-cta .btn-secondary:hover:after,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-within:after,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-visible:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:hover:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-within:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-visible:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:hover:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-within:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-visible:after {
    height: calc(40% - 2px);
    transition-delay: 0.4s;
  }
  .tenant-fwad .c-text-with-cta .btn.btn-primary:hover > a:after, .tenant-fwad .c-text-with-cta .btn.btn-primary:hover > button:after, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-within > a:after, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-within > button:after, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-visible > a:after, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-visible > button:after,
  .tenant-fwad .c-text-with-cta .btn-secondary:hover > a:after,
  .tenant-fwad .c-text-with-cta .btn-secondary:hover > button:after,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-within > a:after,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-within > button:after,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-visible > a:after,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-visible > button:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:hover > a:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:hover > button:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-within > a:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-within > button:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-visible > a:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-visible > button:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:hover > a:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:hover > button:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-within > a:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-within > button:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-visible > a:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-visible > button:after {
    height: calc(40% - 2px);
    transition-delay: 0.4s;
  }
  .tenant-fwad .c-text-with-cta .btn.btn-primary:hover > a:before, .tenant-fwad .c-text-with-cta .btn.btn-primary:hover > button:before, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-within > a:before, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-within > button:before, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-visible > a:before, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-visible > button:before,
  .tenant-fwad .c-text-with-cta .btn-secondary:hover > a:before,
  .tenant-fwad .c-text-with-cta .btn-secondary:hover > button:before,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-within > a:before,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-within > button:before,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-visible > a:before,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-visible > button:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:hover > a:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:hover > button:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-within > a:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-within > button:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-visible > a:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-visible > button:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:hover > a:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:hover > button:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-within > a:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-within > button:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-visible > a:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-visible > button:before {
    width: 100%;
  }
}
.tenant-fwad .c-text-with-cta .btn.btn-primary:before, .tenant-fwad .c-text-with-cta .btn.btn-primary:after,
.tenant-fwad .c-text-with-cta .btn-secondary:before,
.tenant-fwad .c-text-with-cta .btn-secondary:after,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:before,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:after,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary:before,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary:after {
  border-color: #ffec00;
}
.tenant-fwad .c-text-with-cta .btn.btn-primary a:before, .tenant-fwad .c-text-with-cta .btn.btn-primary a:after,
.tenant-fwad .c-text-with-cta .btn.btn-primary button:before,
.tenant-fwad .c-text-with-cta .btn.btn-primary button:after,
.tenant-fwad .c-text-with-cta .btn-secondary a:before,
.tenant-fwad .c-text-with-cta .btn-secondary a:after,
.tenant-fwad .c-text-with-cta .btn-secondary button:before,
.tenant-fwad .c-text-with-cta .btn-secondary button:after,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a:before,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary a:after,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button:before,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary button:after,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary a:before,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary a:after,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary button:before,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary button:after {
  border-color: #ffec00;
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-text-with-cta .btn.btn-primary:hover, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-visible, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-within,
  .tenant-fwad .c-text-with-cta .btn-secondary:hover,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-visible,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-within,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:hover,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-visible,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-within,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:hover,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-visible,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-within {
    background-color: #000000;
    color: #ffffff;
  }
}
.tenant-fwad .c-text-with-cta .btn.btn-primary:focus-within,
.tenant-fwad .c-text-with-cta .btn-secondary:focus-within,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-within,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-within {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-within,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-within,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-within,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-within {
    outline-offset: 1px;
    background-color: #000000;
    color: #ffffff;
    display: inline-flex !important;
  }
  .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-within:before,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-within:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-within:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-within:before {
    width: 100%;
  }
  .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-within:after,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-within:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-within:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-within:after {
    height: calc(40% - 2px);
    transition-delay: 0.4s;
  }
  .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-within > a:after, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-within > button:after,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-within > a:after,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-within > button:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-within > a:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-within > button:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-within > a:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-within > button:after {
    height: calc(40% - 2px);
    transition-delay: 0.4s;
  }
  .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-within > a:before, .tenant-fwad .c-text-with-cta .btn.btn-primary:focus-within > button:before,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-within > a:before,
  .tenant-fwad .c-text-with-cta .btn-secondary:focus-within > button:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-within > a:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary:focus-within > button:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-within > a:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary:focus-within > button:before {
    width: 100%;
  }
}
.tenant-fwad .c-text-with-cta .btn.btn-primary.focus-within,
.tenant-fwad .c-text-with-cta .btn-secondary.focus-within,
.tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary.focus-within,
.tenant-fwad .success-notification .c-text-with-cta .btn-secondary.focus-within {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-text-with-cta .btn.btn-primary.focus-within,
  .tenant-fwad .c-text-with-cta .btn-secondary.focus-within,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary.focus-within,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary.focus-within {
    outline-offset: 1px;
    background-color: #000000;
    color: #ffffff;
    display: inline-flex !important;
  }
  .tenant-fwad .c-text-with-cta .btn.btn-primary.focus-within:before,
  .tenant-fwad .c-text-with-cta .btn-secondary.focus-within:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary.focus-within:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary.focus-within:before {
    width: 100%;
  }
  .tenant-fwad .c-text-with-cta .btn.btn-primary.focus-within:after,
  .tenant-fwad .c-text-with-cta .btn-secondary.focus-within:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary.focus-within:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary.focus-within:after {
    height: calc(40% - 2px);
    transition-delay: 0.4s;
  }
  .tenant-fwad .c-text-with-cta .btn.btn-primary.focus-within > a:after, .tenant-fwad .c-text-with-cta .btn.btn-primary.focus-within > button:after,
  .tenant-fwad .c-text-with-cta .btn-secondary.focus-within > a:after,
  .tenant-fwad .c-text-with-cta .btn-secondary.focus-within > button:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary.focus-within > a:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary.focus-within > button:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary.focus-within > a:after,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary.focus-within > button:after {
    height: calc(40% - 2px);
    transition-delay: 0.4s;
  }
  .tenant-fwad .c-text-with-cta .btn.btn-primary.focus-within > a:before, .tenant-fwad .c-text-with-cta .btn.btn-primary.focus-within > button:before,
  .tenant-fwad .c-text-with-cta .btn-secondary.focus-within > a:before,
  .tenant-fwad .c-text-with-cta .btn-secondary.focus-within > button:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary.focus-within > a:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn.btn-primary.focus-within > button:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary.focus-within > a:before,
  .tenant-fwad .success-notification .c-text-with-cta .btn-secondary.focus-within > button:before {
    width: 100%;
  }
}
.tenant-fwad .success-notification .c-text-with-cta .heading-2 {
  font-size: 30px;
  font-size: 1.875rem;
  letter-spacing: 1px;
  line-height: 1.3;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-fwad .success-notification .c-text-with-cta .heading-2 {
    font-size: 46px;
    font-size: 2.875rem;
    letter-spacing: 1px;
    line-height: 1.3;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .tenant-fwad .success-notification .c-text-with-cta .heading-2 {
    font-size: 54px;
    font-size: 3.375rem;
    letter-spacing: 2px;
    line-height: 1.3;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-fwad .success-notification .c-text-with-cta .heading-2 {
    font-size: 58px;
    font-size: 3.625rem;
    letter-spacing: 2px;
    line-height: 1.2;
  }
}
.tenant-fwad .c-text-with-cta .w--content {
  margin: 0 auto;
}

.c-text-with-cta.iam-reset-password {
  height: 100vh;
}
.c-text-with-cta.iam-reset-password .btn-primary,
.c-text-with-cta.iam-reset-password .w--top {
  display: none;
}
.c-text-with-cta.iam-reset-password .success-icon {
  background: url(../../../assets/img/success-icon.png);
  background-repeat: no-repeat;
  background-size: 72px 72px;
  height: 72px;
  width: 72px;
  font-size: 0;
  margin: 0 auto;
  margin-bottom: 15px;
}
.c-text-with-cta.iam-reset-password .w--middle .w--content {
  padding: 0;
  padding-top: 130px;
}
@media (min-width: 630px) {
  .c-text-with-cta.iam-reset-password .w--middle .w--content {
    padding-top: 140px;
  }
}
.c-text-with-cta.iam-reset-password .w--middle .w--content .title {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: normal;
  line-height: 20px;
  margin: 0;
}
@media (min-width: 630px) {
  .c-text-with-cta.iam-reset-password .w--middle .w--content .title {
    font-family: "GothamBold", "Open Sans", Arial, sans-serif;
    font-size: 18px;
    letter-spacing: -0.1px;
    line-height: 24px;
    margin: 0;
  }
}
.c-text-with-cta.iam-reset-password .w--middle .w--content .description {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: normal;
  margin: 0 auto;
  width: 85%;
}
@media (min-width: 630px) {
  .c-text-with-cta.iam-reset-password .w--middle .w--content .description {
    font-size: 14px;
    width: 70%;
  }
}
.c-text-with-cta.iam-reset-password .w--middle .w--content .description p {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 13px;
  position: relative;
  display: inline-block;
}
.c-text-with-cta.iam-reset-password .w--middle .w--content .description p:after {
  border-bottom: 2px solid #e50000;
  content: "";
  display: block;
  height: 2px;
  left: 0px;
  position: absolute;
  top: 16px;
  width: 100%;
}
@media (min-width: 630px) {
  .c-text-with-cta.iam-reset-password .w--middle .w--content .description p {
    font-size: 14px;
  }
}
.c-text-with-cta.iam-reset-password .w--bottom {
  padding-bottom: 0;
}
.c-text-with-cta.iam-reset-password .w--bottom .textwith-cta-image {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.c-text-with-cta.iam-reset-password .w--bottom .textwith-cta-image img {
  height: 220px;
  width: 160px;
}
@media (min-width: 630px) {
  .c-text-with-cta.iam-reset-password .w--bottom .textwith-cta-image img {
    height: 270px;
    width: 210px;
  }
}